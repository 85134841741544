import * as React from "react";
import Header from "../components/Header";
import Metadata from "../components/Metadata";
import { graphql } from 'gatsby';
import Img from "gatsby-image";

const NotFoundPage = ({data}) => {

return(
    <div className="container">

    <Metadata title="404" description="Page not found" />

    <Header />

      
    <article>
      <div>

        <Img
          className="headshot"
          fluid={data.file.childImageSharp.fluid}
          alt="404_error"
        />

      </div>

        <p style={{marginTop:'50px', textAlign:'center'}}>
            You have stumbled apon an unknown creature. Go back to safety, traveller.
        </p>
    </article>
  </div>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "general/404.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`


export default NotFoundPage
